<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="router-view-container">
        <div class="flex items-center space-x-4 mb-5">
          <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
            <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
          </vs-button>

          <h1 class="page_title">{{ $route.meta.title }}</h1>
        </div>
        <main class="space-y-10">
          <section class="bg-white rounded-2xl p-4 space-y-4">
            <div class="flex items-center justify-between">
              <h1 class="bigTitle">LINE 快速登入</h1>

              <vs-switch color="success" v-model="loginActive" :disabled="!(line_cid && line_csecret)">
                <template #off>
                  <span class="text-sm">已停用</span>
                </template>
                <template #on>
                  <span class="text-sm">啟用中</span>
                </template>
              </vs-switch>
            </div>

            <div class="flex w-full space-x-4">
              <div class="w-full">
                <h2 class="title">Channel ID</h2>
                <div class="relative">
                  <ValidationProvider name="Channel ID" :rules="`${loginActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="line_cid" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>

              <div class="w-full">
                <h2 class="title">Channel Secret</h2>
                <div class="relative">
                  <ValidationProvider name="Channel Secret" :rules="`${loginActive ? 'required|max:40' : ''}`" v-slot="{ errors }">
                    <vs-input v-model="line_csecret" />
                    <errorDiv>{{ errors[0] }}</errorDiv>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </section>
          <hr />
          <div class="flex justify-end">
            <vs-button size="large" ref="saveData" :disabled="invalid" @click="saveData(invalid)"
              ><span class="font-medium">儲存</span></vs-button
            >
          </div>
        </main>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginActive: false,
      line_cid: '',
      line_csecret: '',
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$axios({
        url: 'front/set/app/getLineData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          let data = res.data.Data

          this.line_login = data.line_login
          this.line_cid = data.line_cid
          this.line_csecret = data.line_csecret

          data.line_login === 1 ? (this.loginActive = true) : (this.loginActive = false)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    saveData(invalid) {
      // 如果驗證未通過，return
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.saveData,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      let data = {
        lang: 0,
        line_login: this.line_login,
        line_cid: this.line_cid,
        line_csecret: this.line_csecret,
      }

      this.$axios({
        url: 'front/set/app/uLineData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: data,
      }).then((res) => {
        if (res.data.Success) {
          // 關閉按鈕loading
          loading.close()
          this.$myVS.openNoti(res.data.Message, '', undefined)
          this.loadData()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
  },
  computed: {
    line_login: {
      get() {
        return this.loginActive ? 1 : 0
      },
      //沒加set會報錯
      set() {},
    },
  },
  watch: {
    line_cid: function () {
      if (this.line_cid === '' && this.line_csecret === '') {
        this.loginActive = false
      }
    },
    line_csecret: function () {
      if (this.line_cid === '' && this.line_csecret === '') {
        this.loginActive = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
  line-height: 20px;
}
</style>
